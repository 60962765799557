import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Divider`}</h1>
    <p>{`Divider is a visual break between content.`}</p>
    <ComponentPreview componentName="divider--default-story" hasReact hasAngular hasHTML titleAttr="Divider Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Divider is used to visually separate sections
of content when white space between elements
isn’t enough.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      